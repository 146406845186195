import { MutableRefObject, useMemo, useState } from 'react';
import _orderBy from 'lodash/orderBy';
import * as Yup from 'yup';
import {
    PhoneFramePreview,
    ModelsTemplateEditor,
    WhatsAppTemplatePreview,
    SelectFormGroup,
    type SelectData,
    type SelectDataType
} from '@components';
import { VariableParentKey, useFetch, useTemplate, useTranslator } from '@hooks';
import { CustomizationVariable, SendingTechnologyTypeEnum, SubTool } from '@models';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import type { FormHandles } from '@unform/core';
import { getOnlyNumbers, isDevelopment } from '@utils';
import {
    listMessageTemplatesRoute,
    ListMessageTemplatesAsync,
    SendTestMessage,
    type WhatsAppBusinessMessageTemplatesFilters
} from '~/services/whatsAppBusinessApiService';
import { InputGroupStyled, TestIsNotFreeSpanStyled, TestSendingContainer } from './styles';
import { useValidation } from './validation';

type WhatsAppBusinessTemplateEditorProps = {
    subTool?: SubTool;
    formRef: MutableRefObject<FormHandles>;
    initialValue?: string;
    inputName?: string;
    customizationVariables?: CustomizationVariable[];
    isDefaultTemplate?: boolean;
    subToolKeyIdentifier: VariableParentKey;
    isReduced?: boolean;
    onChangeEnableTechnology?: (checked: boolean, sendingTechnologyType: SendingTechnologyTypeEnum) => void;
    enableTechnology?: boolean;
    subToolColor?: string;
    subToolId: number;
};

const CELL_PHONE_FIELD_NAME = 'CellPhone';
const INPUT_NAME = 'whatsAppMessage';
const SENDING_TECHNOLOGY = SendingTechnologyTypeEnum.WhatsApp;

export const WhatsAppBusinessTemplateEditor = ({
    formRef,
    initialValue,
    inputName = INPUT_NAME,
    customizationVariables = [],
    subToolKeyIdentifier,
    onChangeEnableTechnology,
    subToolColor,
    subToolId,
    enableTechnology = true,
    isReduced = false
}: WhatsAppBusinessTemplateEditorProps) => {
    const { user } = useAuth();
    const { error, success } = useAlert();
    const [isCheckSecurityModalOpen, setIsCheckSecurityModalOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState(initialValue);
    const { generateTemplatePreviewBySendingTechnology } = useTemplate(subToolKeyIdentifier);
    const testInputName = `${inputName}${CELL_PHONE_FIELD_NAME}`;
    const { validate } = useValidation(inputName, testInputName);

    const {
        dialogs: { errors, successes },
        subTools: {
            modelsTemplateEditor: { sendTestMessageButtonText, describedInputPlaceholder },
            whatsAppTemplateEditor: { title, subtitle, noTemplate, testIsNotFree }
        }
    } = useTranslator();

    const {
        data: messageTemplates,
        isLoading: isLoadingTemplates,
        isValidating: isValidatingTemplates
    } = useFetch(
        { url: listMessageTemplatesRoute, filters: { status: 'APPROVED' } as WhatsAppBusinessMessageTemplatesFilters },
        async ({ filters }, signal) => {
            const templates = await ListMessageTemplatesAsync(filters, signal);

            return _orderBy(templates.data, ['name', 'language'], ['asc', 'asc']);
        },
        {
            keepPreviousData: true,
            fallbackData: []
        }
    );

    const formattedMessage = useMemo(() => {
        if (!selectedTemplateId) return { body: '' };

        const selectedTemplate = messageTemplates.find((template) => template.id === selectedTemplateId);

        let header: string;
        let footer: string;

        const body: string = generateTemplatePreviewBySendingTechnology({
            user,
            baseHtml: selectedTemplate?.body?.template ?? '',
            sendingTechnology: SENDING_TECHNOLOGY,
            allowedVariables: []
        });

        if (selectedTemplate?.header?.template) {
            header = generateTemplatePreviewBySendingTechnology({
                user,
                baseHtml: selectedTemplate.header.template,
                sendingTechnology: SENDING_TECHNOLOGY,
                allowedVariables: []
            });
        }

        if (selectedTemplate?.footer?.template) {
            footer = generateTemplatePreviewBySendingTechnology({
                user,
                baseHtml: selectedTemplate.footer.template,
                sendingTechnology: SENDING_TECHNOLOGY,
                allowedVariables: []
            });
        }

        return { header, body, footer };
    }, [user, messageTemplates, selectedTemplateId]); // eslint-disable-line react-hooks/exhaustive-deps

    const whatsAppSelectOptions: SelectData[] = useMemo(() => {
        return messageTemplates.map<SelectData>((messageTemplate) => ({
            id: messageTemplate.id,
            label: `${messageTemplate.name} (${messageTemplate.language})`
        }));
    }, [messageTemplates]);

    const closeCheckSecurityModal = async (recaptcha?: string) => {
        try {
            setIsCheckSecurityModalOpen(false);

            if (recaptcha) {
                const payload = {
                    to: getOnlyNumbers(formRef.current?.getFieldValue(inputName)),
                    text: formattedMessage,
                    recaptchaToken: recaptcha
                };

                // TODO: Revisar por que não ta sendo utilizado
                console.log(payload);
            }
        } catch (exception) {
            captureException(exception);
        }
    };

    const handleClick = async () => {
        try {
            formRef.current.setFieldError(inputName, null);
            formRef.current.setFieldError(testInputName, null);

            const message = formRef.current.getFieldValue(inputName);
            const cellPhoneInputValue = formRef.current.getFieldValue(testInputName);

            await validate(message, cellPhoneInputValue);

            const tagsList = ['utm_source', 'utm_medium', 'utm_campaign'];
            const tagAnalytics = tagsList.reduce((accumulator, tag) => {
                accumulator[tag] = formRef.current.getFieldValue(`trackAnalytics[${tag}]`);
                return accumulator;
            }, {});

            await SendTestMessage({
                to: cellPhoneInputValue,
                templateId: selectedTemplateId,
                subToolId,
                tagAnalytics
            })
                .then((response) => {
                    if (!response?.data?.success) {
                        error(String(errors.failedToSendTestSMS.title), String(errors.failedToSendTestSMS.description));
                    } else {
                        success(String(successes.successfullySendingTestWhatsapp));
                    }
                })
                .catch((exception) => {
                    error(
                        String(errors.failedToSendTestWhatsapp.title),
                        String(errors.failedToSendTestWhatsapp.description)
                    );
                    captureException(exception);
                });
        } catch (exception) {
            if (exception instanceof Yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));

                const currentErrors = formRef.current.getErrors();
                formRef.current.setErrors({ ...currentErrors, ...errorMessages });

                isDevelopment() && console.log(errorMessages);
            } else {
                captureException(exception);
                error(
                    String(errors.failedToSendTestWhatsapp.title),
                    String(errors.failedToSendTestWhatsapp.description)
                );
            }
        }
    };

    const onChangeEnabled = (checked: boolean) => onChangeEnableTechnology(checked, SENDING_TECHNOLOGY);

    const handleOptionChange = (data: SelectDataType) => {
        if (typeof data.id === 'number') {
            data.id = String(data.id);
        }

        setSelectedTemplateId(data.id);
    };

    return (
        <ModelsTemplateEditor
            key={`${INPUT_NAME}${CELL_PHONE_FIELD_NAME}EDITOR`}
            customizationVariables={customizationVariables}
            hasCustomizationVariables={false}
            subToolKeyIdentifier={subToolKeyIdentifier}
            subToolColor={subToolColor}
            subtitle={String(subtitle)}
            title={String(title)}
            closeCheckSecurityModal={closeCheckSecurityModal}
            onChangeEnableTechnology={onChangeEnabled}
            enableTechnology={enableTechnology}
            isCheckSecurityModalOpen={isCheckSecurityModalOpen}
            sendingTechnology={SENDING_TECHNOLOGY}
            formComponents={
                <SelectFormGroup
                    name={inputName}
                    options={whatsAppSelectOptions}
                    disabled={isLoadingTemplates || isValidatingTemplates}
                    placeholder={String(noTemplate)}
                    defaultValue={initialValue}
                    onOptionChange={handleOptionChange}
                />
            }
            inputTestComponent={
                <TestSendingContainer>
                    <InputGroupStyled
                        inputType='tel'
                        inputMask='phone'
                        inputName={testInputName}
                        buttonText={String(sendTestMessageButtonText)}
                        inputPlaceHolder={String(describedInputPlaceholder) + '*'}
                        onClick={handleClick}
                    />
                    <TestIsNotFreeSpanStyled>*{String(testIsNotFree)}</TestIsNotFreeSpanStyled>
                </TestSendingContainer>
            }
            modelPreview={
                <PhoneFramePreview isReduced={isReduced}>
                    <WhatsAppTemplatePreview
                        header={formattedMessage.header}
                        message={formattedMessage.body}
                        footer={formattedMessage.footer}
                    />
                </PhoneFramePreview>
            }
        />
    );
};
